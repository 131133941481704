









import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';
import MenuHintsGuide from '@/_modules/promo/components/menu-hints-guide/menu-hints-guide.vue';

@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    ContactInfoInviter,
    MenuHintsGuide
  },
  computed: {
    // TODO: this block needs refactoring
    ...mapState('eventStore', {
      eventInfo: 'eventInfo',
      showPromoCheckAccessPopup: 'showPromoCheckAccessPopup',
    }),
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      contactInfo: 'promoPageStore/contact',
    }),
  },
})
export default class Promo extends Vue {

  // TODO: this block needs refactoring
  public isAuthenticated: boolean;
  public eventInfo: TEvent;
  public contactInfo: TContact;
  public showPromoCheckAccessPopup: boolean;

  public readonly isOBSSettingsDialogVisible: boolean;
  public readonly isZoomSettingsDialogVisible: boolean;
  public readonly isBroadcastTimeCheckDialogVisible: boolean;
  public readonly isEmbedCodeDialogVisible: boolean;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  // TODO: this block needs refactoring
  public created(): void {
    this.callEventInfo();
    this.callContactInfo();
    this.$store.dispatch('eventStore/callEventTags', this.eventId);
  }

  // TODO: this block needs refactoring
  public mounted(): void {
    this.checkAccess();
  }

  // TODO: this block needs refactoring
  private async callEventInfo(): Promise<void> {
    await this.$store.dispatch('eventStore/event', this.eventId);
  }

  // TODO: this block needs refactoring
  private async checkAccess(): Promise<void> {
    await this.callEventInfo();
    if(this.eventInfo.personal.has_access !== true) {
      await this.$store.dispatch('eventStore/showPromoCheckAccessPopup');
      this.$store.commit('promoStore/promoPageError', '');
      await this.$router.push({
        name: 'event-info',
        params: {
          eventId: this.$route.params.eventId
        }
      });
    }
  }

  // TODO: this block needs refactoring — does it still need it?
  private callContactInfo(): void {
    if (this.contactInfo && this.contactInfo.id) {
      return;
    }
    this.$store.dispatch('promoPageStore/getContact', this.eventId);
  }

}
