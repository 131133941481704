


import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  name: 'menu-hints-guide',
  components: {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      contactInfo: 'promoPageStore/contact',
      event: '_eventStore/event',
      isAuthPopupVisible: 'authStore/isAuthPopupVisible',
      isMenuGuideShown: '_userStore/isMenuGuideShown'
    }),
  },
})
export default class MenuHintsGuide extends Vue {
  public isOpenAccount: boolean = true
  public isOpenSchedule: boolean = false
  public isOpenMessages: boolean = false
  public isOpenNotifications: boolean = false
  public layerTargetHeight: number = 54

  public openHint(value: string): void {
    switch (value) {
      case 'account':
        this.isOpenAccount = !this.isOpenAccount;
        break;
      case 'schedule':
        this.isOpenSchedule = true;
        this.setParams();
        break;
      case 'messages':
        this.isOpenMessages = true;
        this.setParams();
        break;
      case 'notifications':
        this.isOpenNotifications = true;
        this.setParams();
        break;
      default:
        break;
    }
  }

  public setParams(): void {
    const layer = document.querySelectorAll('.layer');
    for (let i = 0; i < layer.length; i++) {
      if (i === 0) {
        (layer[0] as HTMLElement).style.height = (layer[0] as HTMLElement).offsetHeight + this.layerTargetHeight + 'px';
      } else {
        (layer[i] as HTMLElement).style.top = ((layer[i] as HTMLElement).offsetTop + this.layerTargetHeight + 'px');
      }
    }
  }

  public closeHint(): void {
    const layer = document.querySelectorAll('.layer');
    for (let i = 0; i < layer.length; i++) {
      if (i === 0) {
        (layer[0] as HTMLElement).style.height = '';
      } else {
        (layer[i] as HTMLElement).style.top = '';
      }
    }
    this.$store.dispatch('_userStore/menuUserGuideSwitcher', false);
  }
}
